import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Button} from '../Button/Button';
import EmailLeadPopup from '../EmailLeadPopup/EmailLeadPopup';
import './Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    
    const showButton = () => {
      if(window.innerWidth <= 960) {
        setButton(false);
      } else {
        setButton(true);
      }
    }
    
    window.addEventListener('resize', showButton)
    useEffect(() => {
      showButton();
    },[]);

    return (
        <>
          <nav className="navbar">
              <div className="navbar-container">
                <Link to="/" exact className="navbar-logo" onClick={closeMobileMenu}>
                  <img src="/images/yashvi-engineering-services-logo.png" alt="Yashvi Engineering Services Logo" />
                </Link>
                <div className="menu-icon" onClick={handleClick}>
                  <i className={click ? "fas fa-times" : "fas fa-bars"} />
                </div>
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                  <li className="nav-item">
                      <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                        Home
                      </Link>
                  </li>
                  <li className="nav-item">
                      <Link to="/services" className="nav-links" onClick={closeMobileMenu}>
                        Expertise
                      </Link>
                  </li>
                  <li className="nav-item">
                      <Link to="/contact-us" className="nav-links" onClick={closeMobileMenu}>
                        Contact Us
                      </Link>
                  </li>
                  <li className="nav-item">
                      <EmailLeadPopup />
                  </li>
                </ul>
                
              </div>
          </nav>

          <div className='ribbon-box'>
            <ul className="ribbon">
              <li style={{"--accent-color":"#1286A8"}}>
                  <div class="icon"><img alt="NABL Accredited" src="/images/nabl-logo.png" /></div>
                  <div class="title">NABL</div>
                  <div class="descr">We are now NABL accredited.</div>
              </li>
            </ul>
          </div>

          <div className='ribbon-box-small' style={{"--accent-color":"#DA611E"}}>
            We are now NABL Accredited!
          </div>
          

        </>
    );
}

export default Navbar;

/*
<li className="nav-item">
                      <Link to="/brochure" className="nav-links-mobile" onClick={closeMobileMenu}>
                        Brochure
                      </Link>
                  </li>

                  {button && <Button buttonStyle="btn--outline">Services</Button>}

*/
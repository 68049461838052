
export const Address = {
    addressLine1: "331, 3rd Floor, Aarya's Cornor Point,",
    addressLine2: "Opp. Voltamp Transformers Ltd.",
    addressLine3: "Makarpura Main Rd, Maneja,",
    city: "Vadodara",
    zip: "390013",
    state: "Gujrat",
    country: "India",
    fullAddress: "331 Third Floor, Aarya's Corner Point Opp. Voltamp, Makarpura, Main Road, Maneja, Vadodara, Gujarat"
}

export const Phones = ["+91 9898133241"];
export const Emails = ["yashviengg21@gmail.com"];

export const WhatsAppMessage = "Hello Yashvi Engineering Services, Please contact me for discussing my requirements and your solutions.";
export const WhatsAppNumber = "919898133241";
export const WhatsAppBaseUrl = "https://api.whatsapp.com/send/?phone=";
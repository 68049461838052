import React from "react";
import "../../App.css";
import Footer from "../Footer/Footer";
import "./ContactUs.css";
import * as Constants from '../../util/Constants';
import HeroSection from "../HeroSection/HeroSection";
import ContactForm from "../ContactForm/ContactForm";
import Helmet from "react-helmet";

function ContactUs() {
  return (
    <>
      <Helmet>
          <title>Contact Us - Yashvi Engineering Services</title>
      </Helmet>
      <HeroSection 
       videoSrc="/videos/video-2.mp4"
       heading="Contact Us"
       subHeading="We'll be happy to contact you!"
       displayButton="false"
      />
      <div className="wrapper">
      <div className="contact3">
        <div className="row">
          <div className="container">
            <div className="row">              
              <div className="col-lg-6">
                <ContactForm />
              </div>
              <div className="col-lg-6">
                <div className="card-shadow">
                <div className="mapouter"><div className="gmap_canvas"><iframe title="map" className="gmap_iframe" width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?q=331%20Third%20Floor,%20Aarya's%20Corner%20Point%20Opp.%20Voltamp,%20Makarpura,%20Main%20Road,%20Maneja,%20Vadodara,%20Gujarat&t=&z=15&ie=UTF8&iwloc=&output=embed"></iframe><a href="https://kokagames.com/">Koka Games</a></div></div>
                </div>
              </div>
              <div className="col-lg-12 address-section">
                <div className="card mt-4 border-0 mb-4">
                  <div className="row">
                    <div className="col-lg-4 col-md-4">
                      <div className="card-body d-flex align-items-center c-detail pl-0" data-aos="fade-right" data-aos-duration="1050">
                        <div className="mr-3 align-self-start">
                          <img
                            src="/images/address-icon.png"
                            alt="Adreess icon"
                          />
                        </div>
                        <div className="address">
                          <h6 className="font-weight-medium">Address</h6>
                          <p className="">
                            {Constants.Address.addressLine1} <br />
                            {Constants.Address.addressLine2} <br />
                            {Constants.Address.addressLine3} <br />
                            {Constants.Address.city} - {Constants.Address.zip}, {Constants.Address.state} {Constants.Address.country}

                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <div className="card-body d-flex align-items-center c-detail" data-aos="fade-right" data-aos-duration="1050">
                        <div className="mr-3 align-self-start">
                          <img
                            src="/images/phone-icon.png"
                            alt="Phone icon"
                          />
                        </div>
                        <div className="phone">
                          <h6 className="font-weight-medium">Phone</h6>
                          <p className="">
                            {Constants.Phones[0]}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <div className="card-body d-flex align-items-center c-detail"  data-aos="fade-right" data-aos-duration="1050">
                        <div className="mr-3 align-self-start">
                          <img
                            src="/images/email-icon.png"
                            alt="email icon"
                          />
                        </div>
                        <div className="email">
                          <h6 className="font-weight-medium">Email</h6>
                          <p className="">
                            {Constants.Emails[0]}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;

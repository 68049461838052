import React from 'react';
import '../../App.css';
import ServiceDetail from '../ServiceDetail/ServiceDetail';
import Footer from '../Footer/Footer';
import HeroSection from '../HeroSection/HeroSection';
import Helmet from 'react-helmet';

function Services() {
  return (
    <>
      <Helmet>
          <title>Our Expertise - Yashvi Engineering Services</title>
      </Helmet>
      <HeroSection 
       videoSrc="/videos/pvideo-4.mp4"
       heading="Our Expertise"
       subHeading="We are committed to provide the best services!"
       displayButton="false"
      />
      <ServiceDetail />
      <Footer />
    </>
    
  );
}

export default Services;

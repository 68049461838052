import React from 'react';

function ServiceItem(props) {
  return (
    <div className='main' data-aos="fade-right" data-aos-duration="990">
        <div className='service'>
            <div className='service-logo'>
            <img src={props.logoSrc} alt={props.logoAlt} />
            </div>
            <h4>{props.title}</h4>
            <p>
            {props.summary}
            </p>
        </div>
        <div className='shadowOne'></div>
        <div className='shadowTwo'></div>
    </div>
  );
}

export default ServiceItem;

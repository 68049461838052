import React from 'react';
import * as Constants from '../../util/Constants';
import './WhatsApp.css';

function WhatsApp() {

  let url = Constants.WhatsAppBaseUrl + Constants.WhatsAppNumber + '&text=' +  encodeURI(Constants.WhatsAppMessage) + '&app_absent=0';

  return (
    <div className='whatsapp-container'>
        <a href={url} rel="noreferrer" target="_blank">
            <img src="/images/whatsapp.svg" alt="Contact on whatsapp" />
        </a>
    </div>
  )
}

export default WhatsApp
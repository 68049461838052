import React, {useState} from 'react'

function ContactForm() {

  const [status, setStatus] = useState("Submit");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { src, name, email, phone, message } = e.target.elements;
    let details = {
      src: src.value,
      name: name.value,
      email: email.value,
      phone: phone.value,
      message: message.value,
    };
    console.log("details",details);
    let response = await fetch("https://15rca784s5.execute-api.ap-south-1.amazonaws.com/default/send_mail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    let result = await response.json();
    if(result.statusCode === '400') {
        alert("Sorry for inconvenience, some error in sending the email!");
    } else {
        alert("Thank you for your interest, we'll contact you shortly!");
    }
    
  };

  return (
    <>
        <div className="contact-box ml-3">
                  <h1 className="font-weight-light mt-2">Quick Contact</h1>
                  <form className="mt-4" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mt-2">
                        <input
                            type="hidden"
                            value="contact"
                            id="src"
                          />
                          <input
                            className="form-control"
                            type="text"
                            placeholder="name"
                            id="name" required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mt-2">
                          <input
                            className="form-control"
                            type="email"
                            placeholder="email address"
                            id="email" required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mt-2">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="phone"
                            id="phone"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mt-2">
                          <textarea
                            className="form-control"
                            rows="3"
                            placeholder="message"
                            id="message" required
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <button
                          type="submit"
                          className="btn btn-danger-gradiant mt-3 text-white border-0 px-3 py-2"
                        >
                          <span> {status}</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
    </>
  )
}

export default ContactForm;
import React from 'react';
import Slider from '../Slider/Slider';

function ServiceDetailItem(props) {  

  let imgSrcObj = <></>;
  if (props.isSlider === "true") {
    imgSrcObj = <Slider imgs={props.imgs} />
  } else if (props.imgs && props.imgs.length > 0) {    
    imgSrcObj = <img src={props.imgs[0].imgSrc} alt={props.imgs[0].imgAlt} />
  }

  return (
    <>
      <div className='blog-post' data-aos="fade-right" data-aos-duration="1050">
          <div className={(props.imgs && props.imgs.length > 0)? 'blog-post__img' : 'hide'} >
              {imgSrcObj}               
          </div>
          <div className='blog-post__info' >
            <h1 className='blog-post__title'>{props.heading}</h1>
            {props.children}
          </div>
      </div>
    </>
  );
}

export default ServiceDetailItem;
import React from 'react';
import {Carousel} from 'react-bootstrap';
import './Slider.css';

function Slider(props) {
  
  let items = props.imgs.map(img => {
    return (
      <Carousel.Item>
        <img
        className="d-block w-100"
        src={img.imgSrc}
        alt={img.imgAlt}
        />
        <Carousel.Caption className='dark-bg'>
          <h3>{img.imgAlt}</h3>
          {/*<p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
        </Carousel.Caption>
      </Carousel.Item>
    );
  });

  return (
    <>
      <Carousel>
        {items}            
      </Carousel>
    </>
  )
}

export default Slider
import React from 'react';
import '../../App.css';

function AboutUs() {
  return (
      <h1 className='about-us'>About Us</h1>
    );
}

export default AboutUs;

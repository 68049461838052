import React from 'react';
import './Footer.css';
import * as Constants from '../../util/Constants';
import ParticleBackground from '../ParticleBackground/ParticleBackground';
import WhatsApp from '../WhatsApp/WhatsApp';


function Footer() {
  return (
    <>
      

       <div className='footer-wrapper'>
       <ParticleBackground></ParticleBackground>   
           
      <footer>
          
        
        
        <div className="container">        
            <div className="footer-1 line" data-aos="fade-right" data-aos-duration="1050">
                <h2>About Us</h2>
                <div><img src="/images/yashvi-engineering-services-logo.png" alt="Yashvi Engineering Services Logo" /></div>
                <p>We at Yashvi Engineering Services are committed to providing quality services in the field of Civil,
Mechanical and Geotechnical Engineering.</p>
                {/*<ul className="social">
                    <li><a href="/"> <i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                    <li><a href="/"> <i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                    <li><a href="/"> <i className="fa fa-instagram" aria-hidden="true"></i></a></li>
  </ul>*/}
            </div>
            <div className="footer-2 line" data-aos="fade-right" data-aos-duration="1030">
                <h2>Quick Links</h2>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/services">Services</a></li>
                    <li><a href="/contact-us">Contact Us</a></li>
                </ul>
            </div>
            <div className="footer-3 line" data-aos="fade-right" data-aos-duration="990">
                <h2>Contact Info</h2>
                <li>
                    <span><i className="fa fa-map-marker" aria-hidden="true"></i></span>
                    <span>
                    {Constants.Address.addressLine1} <br />
                            {Constants.Address.addressLine2} <br />
                            {Constants.Address.addressLine3} <br />
                            {Constants.Address.city} - {Constants.Address.zip}, {Constants.Address.state} {Constants.Address.country}
                    </span>
                </li>
                <li>
                    <span><i className="fa fa-volume-control-phone" aria-hidden="true"></i></span>
                    <span><a href="/">{Constants.Phones[0]}</a></span>
                </li>
                <li>
                    <span><i className="fa fa-envelope" aria-hidden="true"></i></span>
                    <span><a href="/">{Constants.Emails[0]}</a></span>
                </li>
            </div>
        </div>
        
    </footer>
    </div>
    
    <div className="copyright">
        <p>Copyright &copy; 2022. All Right Reserved.</p>
    </div>
    <WhatsApp />
    </>
  );
}

export default Footer;
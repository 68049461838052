import React from 'react';
import Helmet from 'react-helmet';
import Cards from '../Cards/Cards';
import Footer from '../Footer/Footer';
import HeroSection from '../HeroSection/HeroSection';
import ServicesSection from '../ServicesSection/ServicesSection';

function Home() {
  return (
      <>
        <Helmet>
          <title>Yashvi Engineering Servies</title>
        </Helmet>
        <HeroSection 
         videoSrc="/videos/pvideo-2.mp4"
         heading="Yashvi Engineering Services"
         subHeading="A Pile Load Testing Company"
         displayButton="true"
         />
        <ServicesSection />
        <Cards />        
        <Footer />
      </>
  )
}

export default Home;
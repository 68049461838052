import React from 'react';
import Particles from 'react-tsparticles';
import particlesConfig from './particlesjs-config';
import './ParticleBackground.css';


function ParticleBackground(props) {
  return (
    <div className='particle-bg'>
        <Particles id="tsparticles" options={particlesConfig}></Particles>
        {props.children}
    </div>
  )
}

export default ParticleBackground
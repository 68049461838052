import React,{useState} from 'react';
import {Button, Modal} from'react-bootstrap';
import Axios from 'axios';
import fileDownload from 'js-file-download';

function EmailLeadPopup() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const downloadFile = () => {
    let url = "/ys-brochure.pdf";
    let filename = "YS-Brochure.pdf";
    Axios.get(url, {
      responseType: 'blob',
    }).then(res => {
      fileDownload(res.data, filename);
    });

    handleClose();
  };

  const [status, setStatus] = useState("Continue");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { src, visitor_email } = e.target.elements;
    let details = {
      src: src.value,
      visitor_email: visitor_email.value
    };
    console.log("details",details);
    let response = await fetch("https://15rca784s5.execute-api.ap-south-1.amazonaws.com/default/send_mail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Continue");
    let result = await response.json();
    if(result.statusCode === '400') {
        alert("Sorry for inconvenience, some error in sending the email!");
    } else {

      downloadFile();
        //alert("Thank you for your interest, we'll contact you shortly!");
    }
    
  };

  return (
    <>
      <div className="nav-link-btn">
        <button type="button" className="btn btn-primary" onClick={handleShow}>
          Brochure
        </button>  
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Thank you for your Interest!</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
        <Modal.Body>        
          <div class="form-group">
            <input type="hidden" value="brochure" id="src" />
            <label for="exampleInputEmail1">Email address</label>
            <input type="email" required class="form-control" id="visitor_email" aria-describedby="emailHelp" placeholder="Enter email" />
            <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="primary">{status}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default EmailLeadPopup
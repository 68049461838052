import React from 'react';
import './HeroSection.css';
import {Button} from '../Button/Button';

function HeroSection(props) {
    return(
      <div className='hero-container'>
        <video src={props.videoSrc} autoPlay loop muted />
        <h1>{props.heading}</h1>
        <p>{props.subHeading}</p>
        <div className={(props.displayButton === "true")?"hero-btns":"hero-btns hide"} >
            <Button className='btns' buttonStyle='btn--outline'
              buttonSize='btn--large'>Our Expertise</Button>
        </div>
      </div>
    );
}

export default HeroSection;
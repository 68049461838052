import React from 'react';
import './ServiceDetail.css';
import ServiceDetailItem from './ServiceDetailItem';
import {serviceInfos} from './ServiceDetailsInfo';

function ServiceDetail() {

  let serviceItems = <></>;

  if(serviceInfos) {
    serviceItems = serviceInfos.map(item => {
      return (
        <ServiceDetailItem 
            imgs={item.imgs}
            isSlider={item.isSlider}
            heading={item.heading}>
              {item.text}
            </ServiceDetailItem>
      );
    });
  }
 
  return (
    <>
      <div className='service-container'>
        {serviceItems}      
        <ServiceDetailItem 
          imgs={[]}
          isSlider="false"
          heading="That's not it!">
            <p className='blog-post__text'>
            Our field of specialization is “Pile load Testing”, which includes Static Vertical, Lateral, Pull-Out, High-Strain Dynamics Pile Load test, Low Strain Pile Integrity, foundation testing, Non Destructive Testing, etc.
            </p>
            <p className='blog-post__text'>
            We have the capability to carry out various geo - technical works, foundation testing, including
            </p>
            <div className='row'>
              <div className='col-md-4'>
                <h3>Pile load Test</h3>
                <ul>
                  <li>High Strain Dynamic Pile Test</li>
                  <li>Static Pile Load Test (Vertical, Lateral & Pull-Out)</li>
                  <li>Pile load test by Rock Anchoring</li>
                  <li>Pile load test by Kentledge method</li>
                </ul>
              </div>
              <div className='col-md-4'>
              <h3>Non Destructive Testing</h3>
                <ul>
                  <li>Low Strain Pile Integrity Test</li>
                  <li>Cross Hole Sonic Logging Test</li>
                  <li>Rebound Hammer Test</li>
                  <li>Ultrasonic Pulse Velocity Test</li>
                  <li>Half-cell Potential Test</li>
                  <li>Profometer Test</li>
                  <li>Concrete Core Test</li>
                </ul>
              </div>
              <div className='col-md-4'>
              <h3>Non Destructive Test (Steel)</h3>
                <ul>
                  <li>Ultrasonic Flaw Detection Test</li>
                  <li>Dye Penetrate Test</li>
                  <li>Magnetic Particle Test</li>
                  <li>Radiographic Test</li>
                  <li>Thickness Measurement</li>
                </ul>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-4'>
                <h3>Load Test</h3>
                  <ul>
                    <li>Bridge Load Test</li>
                    <li>Slab Load Test</li>
                    <li>Plate Load Test</li>
                  </ul>
              </div>
              <div className='col-md-4'>
                <h3>Other Field Test</h3>
                  <ul>
                    <li>Electrical Resistivity Test</li>
                    <li>Field CBR</li>
                    <li>Field CBR</li>
                    <li>Compaction test by Core Cutter & Sand Replacement</li>
                  </ul>
              </div>
              <div className='col-md-4'>
                <h3>Geo-technical Exploration</h3>
                  <ul>
                    <li>Soil Drilling</li>
                    <li>Rock Drilling Work (Hard / Soft Rock)</li>
                    <li>Static & Dynamic Cone Penetration Test</li>
                  </ul>
              </div>
            </div>
        </ServiceDetailItem>
      </div>      
    </>
  )
}

export default ServiceDetail;
import React from 'react';
import ServiceItem from './ServiceItem';
import './ServicesSection.css';

function ServicesSection() {
  return (
      <div className='services-section'>
        <div className="twelve">
          <h2>Our Expertise</h2>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <a className='service-link' href='/services'>
              <ServiceItem 
                  logoSrc='/images/service1.png'
                  logoAlt='Static Load Test'
                  title='Static Load Test'
                  summary='Static load testing is used to provide a direct measurement of pile displacement in response to a
                  physically applied test load and is considered to be the bench-mark of pile performance. Suitable
                  for all pile types, static load testing can be carried out on either production piles or sacrificial test
                  piles'
                />
                </a>
            </div>
            <div className='col-md-4'>
            <a className='service-link' href='/services'>
                <ServiceItem 
                  logoSrc='/images/service1.png'
                  logoAlt='Low Strain Pile Integrity Testing'
                  title='Low Strain Pile Integrity Testing'
                  summary='Low Strain Pile integrity testing (also know as the sonic echo or pulse echo method) is a quick and
                  cost effective way of evaluating the integrity of auger cast-in-place piles, bored shafts, driven
                  concrete piles and concrete filled pipes.'
                />
                </a>
            </div>
            <div className='col-md-4'>
            <a className='service-link' href='/services'>
              <ServiceItem 
                  logoSrc='/images/service1.png'
                  logoAlt='High Strain Dynamic Pile Testing'
                  title='High Strain Dynamic Pile Testing'
                  summary='High Strain Dynamic Pile load testing is method of testing deep foundations to obtain information
                  about their capacity and/or to monitor their installation. A Pile Driving Analyser (PDA) is used to
                  record, process and display data and results.'
                />
                </a>
            </div>
          </div>
        </div>
      </div>
  );
}

export default ServicesSection;

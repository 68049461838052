import React from "react";
export const serviceInfos = [
    {
      id:1,  
      heading: "High Strain Dynamic Pile Testing",
      isSlider: "true",
      imgs: [
          {
              imgSrc:"/images/services/eq/High-Strain-Dynamic-Pile-Testing.jpg",
              imgAlt: "High Strain Dynamic Pile Testing"
          },
          {
            imgSrc:"/images/services/eq/High-Strain-Dynamic-Pile-Testing-2.jpg",
            imgAlt: "High Strain Dynamic Pile Testing"
          }
      ],
      text: (<><p className='blog-post__text'>
      High Strain Dynamic Pile testing is suitable for use on all types of drilled and driven piles, and has the advantage of being conducted in a non-destructive manner. They are also less
expensive to conduct that static load tests, which means they can be performed on many more
piles at a much lower cost and faster speed.
      </p>
      <p className='blog-post__text'>
      Dynamic measurements of force and velocity are collected by strain sensors and accelerometers attached to the pile. The PDA monitors the strain and acceleration caused by the hammer impacts, and process these signals after each hammer blow during driving or restrike to give an immediate graphical visual of the loading signals for each blow.
      </p>
      <p className='blog-post__text'>
      Typical results obtained on site include mobilized static load capacity, pile integrity (including the location and extent of any damage), the maximum compression/tension forces at
the pile top and toe, and hammer performance.
      </p>
      <p className='blog-post__text'>
      <strong>Benefits and advantages</strong><br />
      <ul>
        <li>Instant preliminary results on site.</li>
        <li>Instant checks on the pile integrity and the location of any damage.</li>
        <li>Feedback on the maximum tension and compression forces.</li>
        <li>Direct measurements of hammer input energy and efficiency.</li>
        <li>Cost effective.</li>
      </ul>
      </p></>)
    },
    {
      id:2,  
      heading: "Low Strain Pile Integrity Testing",
      isSlider: "true",
      imgs: [
          {
              imgSrc:"/images/services/eq/low-strain-pile-integrity-test.jpg",
              imgAlt: "Low Strain Pile Integrity Testing"
          },
          {
            imgSrc:"/images/services/eq/low-strain-pile-integrity-test-2.jpg",
            imgAlt: "Low Strain Pile Integrity Testing"
          }
      ],
      text:(<>
        <p className='blog-post__text'>
              A low strain pile integrity test is used to detect potential defects such as major cracks, necking, soil inclusions or voids and in some situation, the method can determine unknown lengths of piles. The method does not require advanced planning or access tubes, which makes it the most affordable option when pile integrity is in question.
              </p>
              <p className='blog-post__text'>
              The name “low strain pile integrity test” stems from the fact that when a light impact is applied to a pile it produces a low strain. The impact produces a compression wave that these signals after each hammer blow during driving or restrike to give an immediate graphical
visual of the loading signals for each blow.
              </p>
              <p className='blog-post__text'>
              Typical results obtained on site include mobilized static load capacity, pile integrity (including the location and extent of any damage), the maximum compression/tension forces at
the pile top and toe, and hammer performance.
              </p>
              <p className='blog-post__text'>
              <strong>Benefits and advantages</strong><br />
              <ul>
                <li>Instant preliminary results on site.</li>
                <li>Instant checks on the pile integrity and the location of any damage.</li>
                <li>Feedback on the maximum tension and compression forces.</li>
                <li>Direct measurements of hammer input energy and efficiency.</li>
                <li>Cost effective.</li>
              </ul>
              </p>
      </>)
    },
    {
        id:3,  
        heading: "Static Vertical Pile Load Testing",
        isSlider: "true",
        imgs: [
            {
                imgSrc:"/images/services/eq/static-pile-load-test.jpg",
                imgAlt: "Static Vertical Pile Load Testing"
            },
            {
                imgSrc:"/images/services/eq/static-pile-load-test-2.jpg",
                imgAlt: "Static Vertical Pile Load Testing"
            }
        ],
        text:(<>
            <p className='blog-post__text'>
            Load testing a pile to failure identifies the maximum operating capacity of a pile and also 
            provides valuable information to design engineers. It is recommended for load tests 
            carried out prior to the foundation design, as the outcome of the tests are critical in verifying foundation design parameters and formulating remedial actions if needed. 
            </p>
            <p className='blog-post__text'>
            Testing on production piles is used to prove the pile will perform satisfactorily a the design load plus an overload, demonstrate that a building or structure will be adequately supported and that there is sufficient reserve capacity. 
            </p>
            <p className='blog-post__text'>
            The test pile is subjected to loading using a hydraulic jack, and pressure gauge or load cells of sufficient capacity are used to measure the load. Di rect measurements of pile displacement under the applied weight are logged and the test results presented in a graphical format that shows loading versus pile head displacement.
            </p>
        </>)
    },
    {
        id:4,  
        heading: "Pull-Out Pile Load Test",
        isSlider: "false",
        imgs: [
            {
                imgSrc:"/images/services/PULL-OUT-PILE-LOAD-TEST.jpg",
                imgAlt: "Pull-Out Pile Load Test"
            }
        ],
        text:(<>
          <p className='blog-post__text'>
            Uplift load design and testing of piles has become more prevalent in recent years, in response to an increased use of piles to anchor structure foundations under tension. Uplift force may preferably be applied by means of hydraulic jack(s) with gauge using a suitable pullout set up. Pile top shall preferably be at cut-off level. Displacement measurement shall be made at pile top with at least two dial gauges placed at diametric opposite ends of pile.
            </p>
        </>)
    },
    {
        id:5,  
        heading: "Lateral Pile Load Test",
        isSlider: "false",
        imgs: [
            {
                imgSrc:"/images/services/Lateral-Pile-Load-Test.jpg",
                imgAlt: "Lateral Pile Load Test"
            }
        ],
        text:(<>
          <p className='blog-post__text'>
            Lateral pile load tests have become the primary means for confirming a pile’s ability to resist horizontal movement due to wind loading. These tests are most commonly used during the design phase of solar foundations and help validate an engineer’s calculations regarding the pile’s stiffness and subsurface interaction. The test may be carried out by introducing a hydraulic jack with gauge between two piles or pile groups under test or the reaction may be suitably obtained otherwise. If it is conducted by jack located between two piles or groups, the full load imposed by the jack shall be taken as the lateral resistance of each pile or group. The loading should be applied in increments of about 20 percent of the estimated safe lateral load.
            </p>
        </>)
    },
    {
        id:6,  
        heading: "Cross-Hole Sonic Logging",
        isSlider: "false",
        imgs: [
            {
                imgSrc:"/images/services/Cross-hole-sonic-logging-test.jpg",
                imgAlt: "Cross-Hole Sonic Logging"
            }
        ],
        text:(<>
          <p className='blog-post__text'>
              A tranmitter lowered down into one tube sends a high frequency signal that travels through the
concrete and is detected by a receiver in the second tube. As these transmitters are raised
and lowered along the length of the pile, a cross hole analyser (CHA) records the signals at
regular depth intervals, and the time that the signal takes to travel from the transmitter to the receiver (first arrival time), as well as the signal energy.
            </p>
            <p className='blog-post__text'>
            These two parameters will indicate the concrete quality between the pair of tubes at a particular depth. The entire shaft is logged and the test is then repeated for each pair of tubes, allowing for a full and thorough investigation of faults both along the length and by its quadrant.
            </p>
            <p className='blog-post__text'>
            The results are mapped and presented in graphical format to provided qualitative data once testing is complete. By comparing the graphs from the various combinations of access tubes, the structural soundness of the concrete throughout the pile can be established.
            </p>
            <p className='blog-post__text'>
              <strong>3d TomoSonic Software</strong><br />
              TomoSonic is a 3D tomography imaging tool that supplements the cross hole analysis is useful
in cases where cross hole sonic tests reveal a defect, but does not give a precise indication of its extent.
            </p>
            <p className='blog-post__text'>
            TomoSonic helps visualise the local defect in two or three dimension by producing a 2D or 3D colour coded images of the shaft. The TomoSonic software combines arrival time data from the scans of all pairs of tubes and analyses the data and displays it in various views.
            </p>
        </>)
    },
    {
        id:7,  
        heading: "Bridge Load Testing",
        isSlider: "false",
        imgs: [
            {
                imgSrc:"/images/services/Bridge-load-test.jpeg",
                imgAlt: "Bridge Load Testing"
            }
        ],
        text:(<>
          <p className='blog-post__text'>
              Nondestructive load testing is an effective approach to measure the structural response of a bridge under various loading conditions and to determine its structural integrity. The bridge load testing is considered as a routine requirement now a day’s not only in India but the world over, to meet the requirement of the designs, constructional quality of structure and as an acceptance test conforming to the codal provisions. The load test is carried out mainly to assess the flexural capacity, where in the required parameters can be measured directly and accurately. The super-structures are rarely tested for shear strength evaluation due to absence of any reliable method. The load testing envisaged under IRC: SP: 37 are only for assessing the strength evaluating the load carrying capacity for purposes of rating. Whereas, the load testing under these IRC SP : 51 Guidelines is for assessing the behavior of a bridge by application of design live loads over a longer period of 24 hours for confirmation of the elastic performance of the super-structure.
              </p>
        </>)
    }
  ];
import React from 'react';
import CardItem from './CardItem';

import './Cards.css';

function Cards() {
  return (
    <div className='cards'>
      <h1 className='card-heading'>Who We Are?</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <p>
          <strong>YASHVI ENGINEERIN SERVICES</strong> is an association of independently owned and operated engineering consulting firms that was established in Vadodara, Gujarat in 2021, led by MSU-Baroda graduate. <br />
          We are specialist testing and inspection firm involved in Non Destructive Testing of Pile Foundation and Super Structures using the world's latest state of art techniques and equipments. 
          <br />The team consists of dedicated professionals who have executed challenging projects in India. We take credit for introducing and popularizing NDT of pile foundations & super structures on major infrastructure and real estate projects of the country like Railways, Metro Rails, NHAI, Power sector, Oil & Gas, Offshore and real estate.
          </p>
        </div>
      </div>
      
    </div>
  );
}

export default Cards;

/*
<CardItem 
              src="/images/img-9.jpg" 
              alt="Servce1" 
              text="Pile load testing Service Pile load testing Service"
              label="Pile Load"
              path="/services">
            </CardItem>
            <CardItem 
              src="/images/img-8.jpg" 
              alt="Servce1" 
              text="Pile load testing Service Pile load testing Service dfferr"
              label="Pile Load"
              path="/services">
            </CardItem>
*/
